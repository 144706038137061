import { EnumToArray } from '../../../utils/enumToArray';

enum Appearance {
  Color = 'color',
  Dark = 'dark',
  Light = 'light',
}

export const PTZTabConfigList = {
  Appearance: EnumToArray([Appearance]),
};

export const PTZTabConfig = {
  Appearance,
};
