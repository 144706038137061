ptz-tab {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .ptz-tab {
    display: inline-flex;
    border: none;
    background-color: transparent;
    position: relative;
    padding: $petz-spacing-xs $petz-spacing-2xs calc($petz-spacing-xs - 0.1875rem);
    cursor: pointer;
    overflow: hidden;

    &-a {
      user-select: none;
      font-size: $petz-font-size-sm;
      font-weight: $petz-font-weight-bold;
      line-height: $petz-font-lineheight-lg;
      letter-spacing: 0rem;
      opacity: 1;

      &:hover {
        opacity: $petz-opacity-xl;
      }

      &-skeleton {
        margin: 0;
        padding: 0;
      }
    }

    &-active {
      >.ptz-tab-a,
      >.ptz-tab-icon i {
        opacity: 1;
      }
    }

    &-disabled {
      cursor: not-allowed;

      >.ptz-tab-a,
      >.ptz-tab-icon i {
        opacity: $petz-opacity-sm;
      }
    }

    &-border {
      width: 48px;
      border-radius: $petz-border-md;
      border: $petz-border-sm solid $petz-color-primary-dark;

      &-skeleton {
        width: 48px;
        border-radius: 0;
        height: 2px;
        border: 0;
      }
    }

    &-icon {
      margin-right: $petz-spacing-xs;

      i {
        display: flex;

        &:before {
          line-height: $petz-font-lineheight-lg;
        }
      }

      &-skeleton {
        margin-right: $petz-spacing-2xs;
        padding: 0;
      }
    }

    &-ripple {
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 600ms linear;
      background-color: $petz-color-neutral-black;
      opacity: $petz-opacity-xs;
    }

    &-light {
      >.ptz-tab-a,
      >.ptz-tab-icon i {
        color: $petz-color-neutral-black;
      }

      ~ .ptz-tab-border {
        border-color: $petz-color-primary-dark;
      }
    }

    &-dark,
    &-color {
      >.ptz-tab-a,
      >.ptz-tab-icon i {
        color: $petz-color-neutral-white;
      }

      ~ .ptz-tab-border {
        border-color: $petz-color-primary-light;
      }
    }
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
