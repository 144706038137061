import { Component, h, Host, Prop } from '@stencil/core';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZTabConfig } from './types/ptz-tab.enums';
import { PTZTabTypes } from './types/ptz-tab.types';

@Component({
  tag: 'ptz-tab',
  styleUrl: 'ptz-tab.scss',
  shadow: false
})

export class PTZTab {
  /** Estado de ativo */
  @Prop() active: boolean = false

  /** Estado de renderização */
  @Prop() skeleton: boolean = false

  /** Aparência */
  @Prop() appearance: PTZTabTypes.Appearance = PTZTabConfig.Appearance.Light

  /** Estado de desabilitado */
  @Prop() disabled: boolean = false

  /** Nome do ícone */
  @Prop() iconName: PTZIconTypes.Name

  /** Nome da variante */
  @Prop() iconVariant: PTZIconTypes.Variant

  /** Label */
  @Prop() label: string

  private animateRipple(e: MouseEvent) {
    const button = e.target as HTMLButtonElement

    const circle = document.createElement("span")

    const diameter = Math.max(button.offsetWidth, button.offsetHeight)
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`
    circle.style.left = `${e.clientX - button.offsetLeft - radius}px`
    circle.style.top = `${e.clientY - button.offsetTop - radius}px`
    circle.classList.add("ptz-tab-ripple")

    const ripple = button.getElementsByClassName('ptz-tab-ripple')[0]

    if (ripple) {
      ripple.remove()
    }

    button.appendChild(circle)
  }

  render() {
    return <Host>
      <button
        role="tab"
        disabled={this.disabled}
        aria-selected={this.active ? 'true' : 'false'}
        class={`ptz-tab ${this.appearance ? `ptz-tab-${this.appearance}` : ''} ${this.active ? 'ptz-tab-active' : ''} ${this.disabled ? 'ptz-tab-disabled' : ''}`}
        onClick={(e) => this.animateRipple(e)}
      >
        {this.iconName && this.iconVariant && (
          this.skeleton ?
          <ptz-skeleton class="ptz-tab-icon-skeleton" width={24} height={24} /> :
          <ptz-icon class="ptz-tab-icon" name={this.iconName} variant={this.iconVariant} size='sm'></ptz-icon>
        )}
        {this.skeleton ?
        <ptz-skeleton class="ptz-tab-a-skeleton" width={74} height={24} rounded="2xs" /> :
        <a class="ptz-tab-a">{this.label}</a>}
      </button>
      {this.active && !this.disabled && (
        this.skeleton ?
        <ptz-skeleton class="ptz-tab-border-skeleton" width={50} height={2} rounded="none" /> :
        <span class={`ptz-tab-border`}></span>
      )}
    </Host>
  }
}
